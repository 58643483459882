"use strict";

//
// Prism Initialization
//

Prism.plugins.NormalizeWhitespace.setDefaults({
    'remove-trailing': true,
	'remove-indent': true,
	'left-trim': true,
	'right-trim': true
});
